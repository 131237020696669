.rdgdropdown-toggle::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.917 11.862L.241 5.186a.824.824 0 010-1.166l.78-.779a.824.824 0 011.164 0L7.5 8.53l5.315-5.29a.824.824 0 011.165 0l.778.78a.824.824 0 010 1.165l-6.675 6.676a.825.825 0 01-1.166 0z' fill='%23FFF'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0.3rem 50%;
  margin-right: 0.2rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  border-top: none !important;
  vertical-align: inherit;
}
