.alertDisplay-success{
    background: #F0F8EB;
    border: 1px solid #20AA3F;
    box-sizing: border-box;
}
.iconColor-success{
    color: #2C9A31;
}
.alertDisplay-error{
    background: #FFDFDF;
    border: 1px solid #AA2020;
    box-sizing: border-box;
}
.iconColor-error{
    color: #AA2020;
}
.alertDisplay-warning{
    background: #FFF6EC;
    border: 1px solid #FF9E1B;
    box-sizing: border-box;
}
.iconColor-warning{
    color:#FF9E1B;
}