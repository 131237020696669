/* This CSS file applies to decorator-level styles. */
.main {
  padding: 10px;
  margin-top: 150px;
  overflow: auto;
  /* margin-top: 55px; to account for fixed navigation bar */
}

.themeButton {
  background: #0d3880;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 30px;
  margin: 0 20px;
}

.selectUserDropDown {
  width: inherit;
}

.linkSymbol {
  margin: 0;
  padding: 0 5px 0 5px;
}

.dropdown,
.combobox {
  position: relative;
}
.combobox > .downarrow,
.dropdown > .downarrow {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1.25em;
  cursor: default;
  nav-index: -1; /* nonfunctional in most browsers */
  border-width: 0px; /* disable by default */
  border-style: inherit; /* copy parent border */
  border-color: inherit; /* copy parent border */
}

.userDetailsContent {
  display: block;
  pointer-events: none;
  padding: 5px;
  background-color: #fff;
  transition: 0.4s; /* fade out */
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid #888;
  background-color: #fff;
  box-shadow: 1px 2px 4px 1px #666;
  box-shadow: 1px 2px 4px 1px #4448;
  z-index: 9999;
  min-width: 100%;
  box-sizing: border-box;
}

.userTextStyles {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  align-items: center;
  padding-right: 50px;
  color: #ffffff;
  display: flex;
  margin-left: auto;
}

.userClickableContent {
  display: flex;
  height: 60px;
  width: 206px;
  border-radius: 4px;
  background: #cc8454;
  align-items: center;
}

.chevronUserDropdown {
  width: 0;
  height: 0;
  border-left: 5px solid #cc8454;
  border-right: 5px solid #cc8454;
  border-top: 5px solid #fff;
  align-self: center;
  background: #ffffff;
  margin-left: 4px;
}

.userSignCircle {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background: white;
  border: 1px solid transparent;
  margin-right: 12px;
  color: black;
  margin: 5px;
}

.userSignCircle div {
  margin-top: 8px;
}

.chevronUserDropdownUp {
  width: 0;
  height: 0;
  border-left: 5px solid #cc8454;
  border-right: 5px solid #cc8454;
  border-bottom: 5px solid #ffffff;
  align-self: center;
  background: #ffffff;
  margin-left: 4px;
}

.layoutContainer {
  width: 70%;
  margin: 20px auto;
  text-align: center;
}

.headerText {
  font-size: 40px;
  font-weight: 700;
  color: #0d3880;
}

.layoutContainer div div {
  display: inline-block;
  padding: 10px;
  width: 19%;
  border: 1px solid #978b8b;
  margin: 0 24px;
}

.layoutContainer div div p {
  margin: 0;
  font-size: 15px;
  cursor: pointer;
}

.layoutContainer div div p span {
  padding: 10px;
  border: 1px solid #173a7b;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background: transparent;
}
.layoutContainer .activeManual div:first-child span {
  background: #173a7b;
}
.layoutContainer .activeImport div:last-child span {
  background: #173a7b;
}

.dashboardTitle {
  background: #f4f4f4;
  padding: 60px 100px;
  font-size: 36px;
  font-weight: 700;
  margin: 20px 0;
}
.dashboardBody {
  margin: 50px;
  width: 70%;
  margin: 20px auto;
}
.dashboardBody .col {
  padding: 10px;
  margin: 15px;
}
.dashboardBody h2 {
  font-size: 36px;
  font-weight: 400;
  padding: 15px;
}
.dashboardBody .col:last-child {
  background: #feefe6;
  border: 1px solid #cc8354;
  border-radius: 8px;
}
.dashboardBody .tableContainer {
  padding: 10px;
}
.dashboardBody .tableContainer input[type="text"] {
  max-width: 500px;
}
.paginationCont {
  text-align: end;
}
.secondaryButton {
  background: #cc8354;
  border: none;
}
.secondaryButton:hover {
  background: #feefe6;
  color: #cc8354;
}
.dashboardBody .col p {
  padding: 10px;
  line-height: 32px;
  font-size: 16px;
}
.recordsTable tbody tr td p {
  margin: 4px;
  max-width: 700px; /* To avoid date column breaking*/
}

.recordsTable tbody tr td p a {
  /* color: #CC0000; */
  text-decoration: none;
  margin-right: 15px;
}

.recordsTable tbody tr td p:first-child a {
  color: #0d3880;
  font-weight: bold;
}

.recordsTable th:last-child {
  text-align: center;
  padding-right: 12px;
}

/* batch component styles */
.batchContainer {
  width: 40%;
  margin: 30px 160px;
}

.batchContainer p,
.batchContainer h4 {
  margin: 10px 0;
  font-size: 18px;
}

.batchContainer .batchPreview {
  color: #0d3880;
  border: 1px solid #0d3880;
  padding: 10px;
  width: 250px;
  margin: 15px 0 50px;
}
.buttonTD {
  text-align: end;
}

.textCenter {
  text-align: center;
}

.mr_15 {
  margin-right: 15px;
}

.validContainer {
  padding: 10px;
  background: #fff6ec;
  border: 1px solid #ff9e1b;
}

.addRecord {
  display: inline-block;
}

.addRecord button {
  background: #0d3880;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 30px;
  margin: 0 20px;
}
.search-heading {
  margin: 30px 0px 0px 30px;
}
.grid-centered {
  margin-left: auto;
  margin-right: auto;
  float: none;
}
.search-row {
  max-width: 71.111em;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
}
.text-center {
  text-align: center;
}
.indexPageSearchInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 400px;
  font-size: inherit;
  padding: 0.7em 0.7em;
  display: inline-block;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
}
.show-for-sr {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.search-button {
  border-radius: 8px;
  margin-left: -5px;
  background: #4f56a5;
  color: #fff;
  border: 1px solid #4f56a5;
  letter-spacing: 1px;
  padding: 11px 10px;
}
.radio-align {
  float: left;
}
.radio-style {
  margin-bottom: 10px;
}
.pill-style {
  background-color: #bfbece;
  border-radius: 16px;
  color: #222222;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}
