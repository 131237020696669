@import '@/styles/variables.module.scss';

.rdg-button {
    color: #fff;
    font-size: 18px;
    background: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    padding: 6px 20px;
    width: auto;
    
    &.fit-content {
        width: fit-content;
    }

   
    
    &.btn-primary {
        background: $primary-color;
        &:hover {
            background: #8C91C8;
        }
        &:focus {
            background: $primary-color;
        }
        &:active{
            background: #393E76;
        }
        &.btn-outline {
            background: transparent !important;
            border: 1px solid $primary-color;
            color: $primary-color;
            &:hover {
                background: #B2B6DA !important;
            }
            &:focus {
                border:2px solid $primary-color !important
            }
            &:active {
                background: $primary-color !important;
                color: #fff;
            }
        }

        &:disabled {
            cursor: not-allowed;
            color: #8C91C8;
          
        }
    }
    
    &.btn-secondary {
        background: $secondary-color;
        &:hover {
            background: #D7844D;
        }
        &:focus {
            background: $secondary-color;
        }
        &:active{
            background: $secondary-color;
        }
        &.btn-outline {
            background: transparent !important;
            border: 1px solid $secondary-color;
            color: $secondary-color;
            &:hover {
                background: #E3A982 !important;
            }
            &:focus {
                border:2px solid $secondary-color !important
            }
            &:active {
                background: $secondary-color !important;
                color: #fff;
            }
        }
    }

    &.btn-link {
        background: none;
        border: none;
        color: #CD0000;

        &:hover {
            background: none;
            text-decoration: underline;
        }
    }
    
    &.btn-lg {
        height: 54px;
    }
    
    &.btn-md {
        height:48px
    }

    &.btn-sm {
        min-width:78px;
        height:40px
    }
    
    
}